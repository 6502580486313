import { Pagination, Spin, Tabs, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import infoIcon from '../../assets/images/infoIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import ExpertRequestCard from '../common/expertRequestCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';

export const ExpertRequestList = () => {
  const dispatch = useDispatch();
  const {
    requests,
    isRequestLoading,
    expertNewRequests,
    isExpertNewRequestStatusLoading,
    expertActiveRequests,
    isExpertActiveRequestStatusLoading,
    isUpdateQuestionStatusLoading,
    isAcceptOrRejectExpertRequestLoading,
    getExpertRequestCountFromApi,
  } = useSelector(state => state.requests);
  const { isCommonUpdateLoading } = useSelector(state => state.common);
  const { expertCreditById, expertEarningById } = useSelector(state => state.expert);
  const navigate = useNavigate();
  const [paginationMinIndex, setPaginationMinIndex] = useState(0);
  const [paginationMaxIndex, setPaginationMaxIndex] = useState(0);
  const [paginationElementsPerPage] = useState(4);
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const [paginationAllElements, setPaginationAllElements] = useState([]);
  const [, setPaginationPagesCount] = useState(1);
  const [selectedTabName, setSelectedTabName] = useState('activeRequest');
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabValue = params.get('tab')
    if(tabValue) {
      setSelectedTabName(tabValue);
    }
  },[location.state])
  //Getting expert credit.
  useEffect(() => {
    dispatch({
      type: 'GET_EARNING_BY_ID',
      formData: {
        client_id: getUserData().id,
      },
    });
    dispatch({ type: 'GET_CREDIT_BY_ID', id: getUserData().id });
    dispatch({
      type: 'GET_EXPERT_REQUEST_COUNT',
      formData: {
        type: 'request',
        status: 'active',
      },
    });
  }, []);

  // Fetching question list
  useEffect(() => {
    if (selectedTabName === 'question') {
      dispatch({ type: 'GET_REQUESTS', formData: { type: 'question' }, reqFrom: 'expert' });
    }
  }, [
    dispatch,
    paginationElementsPerPage,
    selectedTabName,
    isUpdateQuestionStatusLoading,
    isCommonUpdateLoading,
    isAcceptOrRejectExpertRequestLoading,
  ]);

  // Fetching Active Request List
  useEffect(() => {
    if (selectedTabName === 'activeRequest') {
      dispatch({ type: 'GET_REQUESTS', formData: { type: 'question' }, reqFrom: 'expert' });
      dispatch({ type: 'GET_EXPERT_ACTIVE_REQUEST' });
    }
  }, [dispatch, isUpdateQuestionStatusLoading, selectedTabName, isCommonUpdateLoading]);

  // Fetching Expired Request List
  useEffect(() => {
    if (selectedTabName === 'newRequest') {
      dispatch({ type: 'GET_EXPERT_NEW_REQUEST' });
    }
  }, [
    dispatch,
    isUpdateQuestionStatusLoading,
    isAcceptOrRejectExpertRequestLoading,
    selectedTabName,
    isCommonUpdateLoading,
  ]);

  // Applying pagination on question tab.
  useEffect(() => {
    if (requests?.data?.length) {
      setPaginationAllElements(requests?.data);
      setPaginationPagesCount(requests?.data / paginationElementsPerPage);
      setPaginationMinIndex(0);
      setPaginationMaxIndex(paginationElementsPerPage);
    }
  }, [
    dispatch,
    paginationElementsPerPage,
    isUpdateQuestionStatusLoading,
    selectedTabName,
    requests?.data?.length,
    isCommonUpdateLoading,
    requests,
  ]);

  const setTabNameAndKey = data => {
    switch (data) {
      case 1:
        setSelectedTabName('activeRequest');
        break;
      case 2:
        setSelectedTabName('newRequest');
        break;
      case 3:
        setSelectedTabName('question');
        break;
      default:
        return;
    }
  };

  const onActiveRequestsClick = data => {
    navigate(`/dashboard/expert/request/active/${data.id}`, {
      state: data,
    });
  };

  const onNewRequestsClick = data => {
    navigate(`/dashboard/expert/request/new/${data.id}`, {
      state: data,
    });
  };

  const onRejectNewQuestion = id => {
    dispatch({
      type: 'UPDATE_QUESTION_STATUS',
      formData: { question_id: id, question_status_id: 10 },
    });
  };

  const onAcceptNewRequest = (id, type) => {
    dispatch({
      type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: id, accepted: 1 },
    });
    
  };

  const onRejectNewRequest = (id, type) => {
    dispatch({
      type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: id, accepted: 0 },
    });
  };

  const onPaginationChange = page => {
    setPaginationCurrent(page);
    setPaginationMinIndex((page - 1) * paginationElementsPerPage);
    setPaginationMaxIndex(page * paginationElementsPerPage);
  };

  const activeRequests = () => {
    return (
      <>
        {isExpertActiveRequestStatusLoading ? (
          <div
            style={{ height: (height * 25) / 100 }}
            className="expert-empty-new-request-container"
          >
            <Spin />
          </div>
        ) : expertActiveRequests?.data?.length ? (
          expertActiveRequests?.data?.map(el => {
            return (
              <ExpertRequestCard
                {...el}
                type={'ActiveRequest'}
                isClickable={true}
                onClick={() => onActiveRequestsClick(el)}
                
              />
            );
          })
        ) : (
          <div
            style={{ height: (height * 25) / 100 }}
            className="d-flex expert-empty-new-request-container"
          >
            No Active Requests Found.
          </div>
        )}
      </>
    );
  };

  const newRequests = () => {
    return isExpertNewRequestStatusLoading ? (
      <div style={{ height: (height * 25) / 100 }} className="expert-empty-new-request-container">
        <Spin />
      </div>
    ) : expertNewRequests?.data?.length ? (
      expertNewRequests?.data?.map(el => {
        return (
          <ExpertRequestCard
            {...el}
            type={'NewRequest'}
            isClickable={true}
            onClick={() => onNewRequestsClick(el)}
            onAcceptRequestClick={() => onAcceptNewRequest(el.id, 'request')}
            onRejectRequestClick={() => onRejectNewRequest(el.id, 'request')}
            setTabNameAndKey={setTabNameAndKey}
          />
        );
      })
    ) : (
      <div style={{ height: (height * 25) / 100 }} className="expert-empty-new-request-container">
        No New Requests Found.
      </div>
    );
  };

  const currentQuestions = () => {
    return isRequestLoading ? (
      <div style={{ height: (height * 25) / 100 }} className="expert-empty-new-request-container">
        <Spin />
      </div>
    ) : requests?.data?.length ? (
      <>
        {paginationAllElements?.map(
          (el, index) =>
            index >= paginationMinIndex &&
            index < paginationMaxIndex && (
              <div key={index}>
                <ExpertRequestCard
                  {...el}
                  type={'QuestionCard'}
                  isClickable={true}
                  // onClick={() => onActiveRequestsClick(el)}
                  onAcceptRequestClick={() => onAcceptNewRequest(el.id, 'question')}
                  onRejectRequestClick={() => onRejectNewQuestion(el.id)}
                />
              </div>
            ),
        )}
        <Pagination
          className="question-container-pagination"
          pageSize={paginationElementsPerPage}
          current={paginationCurrent}
          total={paginationAllElements.length}
          onChange={onPaginationChange}
        />
      </>
    ) : (
      <div style={{ height: (height * 25) / 100 }} className="expert-empty-new-request-container">
        No New Questions Found.
      </div>
    );
  };

  const tabBarItems = [
    {
      label: `Active Requests`,
      key: 1,
      children: activeRequests(),
    },
    {
      label: `New Requests`,
      key: 2,
      children: newRequests(),
    },
    {
      label: `New Questions`,
      key: 3,
      children: currentQuestions(),
    },
  ];
  const activeKey = tabBarItems.find(item => {
    switch (selectedTabName) {
      case 'activeRequest':
        return item.label === 'Active Requests';
      case 'newRequest':
        return item.label === 'New Requests';
      case 'question':
        return item.label === 'New Questions';
      default:
        return false;
    }
  })?.key;
  return (
    <div className="expert-container">
      <div className="expert-requests-title">My Requests</div>
      <div className="expert-question-stats-container">
        <div className="expert-question-stats-card">
          <div className="expert-question-stats-card-content-wrapper">
            <div className="expert-question-stats-card-content-title"  onClick={() => setTabNameAndKey(2)}>New Requests</div>
            <div className="expert-question-stats-card-content-value" onClick={() =>setTabNameAndKey(2)}>
              {expertActiveRequests?.data?.length
                ? getExpertRequestCountFromApi - expertActiveRequests?.data?.length
                : 0}
            </div>
          </div>
        </div>
        <div className="expert-question-stats-card">
          <div className="expert-question-stats-card-content-wrapper">
            <div className="expert-question-stats-card-content-title" onClick={() => setTabNameAndKey(3)}>New Questions</div>
            <div className="expert-question-stats-card-content-value" onClick={() => setTabNameAndKey(3)}>
              {requests?.data?.length ? requests?.data?.length : 0}
            </div>
          </div>
        </div>
        <div className="expert-question-stats-card">
          <div className="expert-question-stats-card-content-wrapper">
            <div className="expert-question-stats-card-content-title">Total Earnings</div>
            <div className="d-flex expert-question-stats-card-content-title-container">
              <div className="expert-question-stats-card-content-value">
                CA${expertCreditById?.credit?.amount ? expertCreditById?.credit?.amount : '00.00'}
              </div>
              <div className="d-flex-a-c expert-question-stats-card-content-sub-value"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="expert-tab-navigator-container">
        <Tabs
          activeKey={activeKey}
          items={tabBarItems}
          onChange={key => setTabNameAndKey(key)}
        />
      </div>
    </div>
  );
};
