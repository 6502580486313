import React, { useEffect, useState } from 'react';
import NotificationCard from './NotificationCard';
import { convertDateToTimezone, convertTimeToTimezone, formatNotification, getUserData } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

const Notifications = () => {
  const notifications = useSelector(state => state?.common?.notifications);
  const page = useSelector(state => state?.common?.currentNotificationPage);
  const dispatch = useDispatch();
  console.log('notifications:', notifications);
  function getNotification() {
    dispatch({
      type: 'GET_NOTIFICATION',
      created_at:
        notifications[notifications.length - 1]?.created_at ||
        getUserData()?.notifications[getUserData()?.notifications?.length - 1]?.created_at,
    });
  }
  
  function getDate(n){
    console.log('n: ',n);
    if(n.created_at){
     return convertDateToTimezone(n?.created_at) + ' - ' + convertTimeToTimezone(n?.created_at);
    }
    else{
      return convertDateToTimezone(JSON.parse(n?.data[0])?.notification?.created_at) + ' - ' + convertTimeToTimezone(JSON.parse(n?.data[0])?.notification?.created_at);
    //  return  new Date(JSON.parse(n?.data[0])?.notification?.created_at).toLocaleString();
    }
   }
  // JSON.parse(n?.data)?.message
  return (
    <>
      {notifications?.length === 0
        ? 'No new notifications'
        : notifications.map((n, i) => {
            return (
              <>
                <NotificationCard notification={formatNotification(n)[1]} time={getDate(n)} />
              </>
            );
          })}
      <Row
        style={{
          // backgroundColor: 'white',
          // border: '2px solid black',
          borderRadius: '1rem',
          padding: '1rem',
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col span={12} style={{ textAlign: 'center' }}>
        <span
        onClick={() => {
          getNotification();
        }}
        style={{
          marginTop: '2rem',
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'underline',
          // backgroundColor: 'red',
        }}
      >
        Load more
      </span>
        </Col>
        
      </Row>
      
    </>
  );
};

export default Notifications;
