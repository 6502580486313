import { CloseCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';

const ZoomMeeting = ({ setShowZoomMeeting }) => {
  const expertData = useSelector(state => state?.common?.questionDetail);
  return (
    <div className="zoom-meeting-prechatview">
      {/* <input
        type="text"
        placeholder="Enter message here..."
        style={{
          width: '100%',
          border: 'none',
          backgroundColor: 'transparent',
          outline: 'none',
          padding: '10px 10px',
        }}
      /> */}
      <div
        style={{
          width: '30%',
          height: '80%',
          backgroundColor: 'rgba(28,100,242)',
          borderRadius: '8px',
          padding: '10px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <div>
            <h1 style={{ color: 'white' }}>Meeting with</h1>
            <h3 style={{ color: 'rgba(255,255,255,0.5)' }}>{expertData?.expert?.first_name + ' ' + expertData?.expert?.last_name}</h3>
          </div>
          <CloseCircleOutlined
            onClick={() => setShowZoomMeeting(false)}
            style={{ color: 'white', fontSize: '1.25rem' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>
          <VideoCameraOutlined style={{ color: 'rgba(255,255,255,0.5)', fontSize: '6rem' }} />
        </div>
      </div>
    </div>
  );
};

export default ZoomMeeting;
