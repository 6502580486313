import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { postRequest } from '../../../helpers/apiCall';

const ImagePreviewModal = ({ isModal, setIsModal, imgsrc }) => {
  
  return (
    <Modal
      visible={isModal}
      footer={null}
      onCancel={() => setIsModal(false)}
      width="50%"
    >
      <img width={'100%'} src={imgsrc} alt='Loading...'></img>
    </Modal>
  );
};

export default ImagePreviewModal;
