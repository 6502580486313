import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  convertTimeToTimezone,
  dynamicMessagingCard,
  formatDateTo,
  getUserData,
  role,
  showCustomAvatarName,
} from '../../helpers/functions';
import { MESSAGE_TYPE, ROLE_NAME } from '../../helpers/constant';


const LeftMessage = ({ m, selectedThreadData, type, handleMessageClick,params }) => {
  const { body, created_at, attachment_type, message_type } = m;
  let msg = body;
  let attachment = attachment_type;
  if (
    message_type == MESSAGE_TYPE.JOIN_MEETING ||
    message_type == MESSAGE_TYPE.PICK_DATE_AND_TIME ||
    message_type == MESSAGE_TYPE.SCHEDULE_MEETING
  ) {
    msg = JSON.parse(body);
    attachment =
      (message_type == MESSAGE_TYPE.JOIN_MEETING && 'zoom') ||
      (message_type == MESSAGE_TYPE.PICK_DATE_AND_TIME && 'calendar') ||
      (message_type == MESSAGE_TYPE.SCHEDULE_MEETING && 'schedule');
  }
  const [expertAvatar, setExpertAvatar] = useState();
  const [customerDetail, setCustomerDetail] = useState();

  useEffect(() => {
    if (type === 'group') {
      const expertData =
        role() === ROLE_NAME.EXPERT
          ? selectedThreadData[0]?.participants?.filter(data => data?.id === getUserData().id)
          : selectedThreadData[0]?.participants?.filter(data => data?.id !== getUserData().id);
      if (expertData?.length) {
        setExpertAvatar(role() === ROLE_NAME.CUSTOMER ? expertData[0]?.expert_meta?.avatar : null);
      }
      if (role() === ROLE_NAME.EXPERT) {
        setCustomerDetail(
          selectedThreadData[0]?.participants?.filter(data => data?.id !== getUserData().id),
        );
      }
    } else if (type === 'container') {
      setExpertAvatar(role() === ROLE_NAME.CUSTOMER && selectedThreadData[0]?.expert_meta?.avatar);
    }
  }, []);

  return (
    <div className="message-container" data-indexKey={m?.id}>
      {expertAvatar ? (
        <img
          style={{ height: '40px', width: '40px', borderRadius: '50%', marginBottom: '15px' }}
          src={expertAvatar}
          alt=""
        />
      ) : type === 'container' ? (
        <Avatar style={{ marginBottom: '15px' }} size="large" className="pointer">
          {showCustomAvatarName(
            `${selectedThreadData[0]?.first_name} ${selectedThreadData[0]?.last_name}`,
          )}
        </Avatar>
      ) : (
        <Avatar style={{ marginBottom: '15px' }} size="large" className="pointer">
          {customerDetail
            ? showCustomAvatarName(
                `${customerDetail[0]?.first_name} ${customerDetail[0]?.last_name}`,
              )
            : '-'}
        </Avatar>
      )}
      <div>
        {/* {dynamicMessagingCard(attachment_type, body)} */}
        <div onClick={() => handleMessageClick(message_type, msg,m)} style={{ cursor: 'pointer' }}>
          {dynamicMessagingCard(attachment, msg, '', m , params)}
        </div>
        <div className="message-time">
          {/* <p>{formatDateTo(created_at, 'hh:mm A')}</p> */}
          <p>{convertTimeToTimezone(created_at)}</p>
        </div>
      </div>
    </div>
  );
};

export default LeftMessage;
