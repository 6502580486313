import React from 'react';
import { Row, Col } from 'antd';
import { getUserData } from '../../helpers/functions';
const NotificationCard = ({ notification , time }) => {
  return (
    <>
      <Row
        style={{
          backgroundColor: 'white',
          border: '2px solid black',
          borderRadius: '1rem',
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <Col span={12}>{notification}</Col>
        <Col span={12}>{time}</Col>
      </Row>
    </>
  );
};

export default NotificationCard;
