import React, { useEffect, useRef, useState } from 'react';
import attachment from '../../assets/images/attachment.svg';

import { ArrowLeftOutlined, CloseCircleOutlined, SendOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import LeftMessage from './leftMessageCard';
import RightMessage from './rightMessagingCard';
import DailyIframe from '@daily-co/daily-js';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import {
  dynamicMessagePayloadWithAttachment,
  getFullName,
  getUserData,
  isDocsType,
  isExcelType,
  isImageType,
  isPdfType,
  placeholderBasedOnFileType,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import AddMeetingTime from './modal/addMeetingTime';
import ZoomMeeting from './ZoomMeeting';
// import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import { ZOOM_MEETING_SDK_KEY } from '../../helpers/constant';
import { usePusher } from './PusherContext';
import { useParams } from 'react-router-dom';
import { postRequest } from '../../helpers/apiCall';
import ImagePreviewModal from './modal/ImagePreviewModal';

export const MessagingContainerForMessageGroup = props => {
  const client = '';
  const { channel } = usePusher();
  let meetingSDKElement = document.getElementById('meetingSDKElement');
  const messageInputRef = useRef();
  const message_area_ref = useRef();
  const callFrameRef = useRef(null);
  const [callFrame, setCallFrame] = useState(null);
  const [isCallActive, setIsCallActive] = useState(true);
  const { type, subjectId, selectedTabGroup, selectedThreadData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFileType, setSelectedFileType] = useState('message');
  const { docURL } = useSelector(state => state.common);
  const [selectedImageFile, setSelectedImageFile] = useState('');
  const [showZoomMeeting, setShowZoomMeeting] = useState(false);
  const [isLoadPrevMessage, setIsLoadPrevMessage] = useState(false);
  const [meetingBackButtonVisible, setMeetingBackButtonVisible] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [newMessageLoad, setMessageLoad] = useState(false);
  const params = useParams();
  const [isUserSendingItsFirstMessage, setIsUserSendingItsFirstMessage] = useState(false);
  const {
    threadBySubjectId,
    imageUrlFromDB,
    isImageUploadingToDBLoading,
    isPostMessageLoading,
    zoomMeetingSignature,
    zommMeetingSignatureLoading,
    isAddNewMessageInThread,
    addedMessagesLastIndexId,
  } = useSelector(state => state.requests);
  const userData = getUserData();
  const expertData = props.selectedThreadData?.[0]?.participants.filter(
    data => data?.id !== userData?.id,
  )?.[0];
  const dispatch = useDispatch();
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const messagesEndRef = useRef(null);
  const [isUserThreadAlreadyExists, setIsUserThreadAlreadyExists] = useState(true);
  useEffect(() => {
    if (!isAddNewMessageInThread) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      messageInputRef?.current?.focus();
    }
    if (subjectId && threadBySubjectId?.messages && threadBySubjectId?.messages?.length > 0) {
      dispatch({
        type: 'MARK_ALL_MESSAGES_AS_READ',
        formData: { thread_id: threadBySubjectId?.messages?.[0]?.thread_id },
      });
    }
    setMessageLoad(false);
  }, [
    threadBySubjectId?.messages?.length,
    messageInputRef?.current?.value,
    isPostMessageLoading,
    isLoading,
    messagesEndRef.current,
  ]);

  useEffect(() => {
    if (selectedTabGroup !== '' && !isNaN(subjectId)) {
      dispatch({ type: 'GET_THREAD_BY_SUBJECT_ID', id: subjectId });
    }
  }, [selectedTabGroup]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [selectedTabGroup]);

  const handleImageUpload = info => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      if (isImageType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('image');
        }, 10);

        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        formData.append('path', subjectId);
        formData.append('modeOfFile', 'private');
        dispatch({ type: 'UPLOAD_IMAGE_TO_DB', formData: formData });
      } else if (isPdfType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('pdf');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        formData.append('path', subjectId);
        formData.append('modeOfFile', 'private');
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isExcelType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('excel');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        formData.append('path', subjectId);
        formData.append('modeOfFile', 'private');
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isDocsType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('docs');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        formData.append('path', subjectId);
        formData.append('modeOfFile', 'private');
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else {
        setSelectedFileType('message');
      }
    }
  };
  const createNewThread = () => {
    dispatch({
      type: 'POST_NEW_MESSAGE_THREAD',
      formData: {
        subject: props?.subjectId,
        message: '',
        recipients: props?.expertId,
      },
    });
    setIsUserSendingItsFirstMessage(!isUserSendingItsFirstMessage);
    setIsUserThreadAlreadyExists(!isUserThreadAlreadyExists);

  }
  // const onMessageSend = e => {
  //   if (showZoomMeeting) {
  //     dispatch({
  //       type: 'POST_ZOOM_MEETING',
  //       formData1: {
  //         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //         topic: 'Zoom Meeting',
  //         agenda: `${getFullName()} and ${expertData?.first_name} ${expertData?.last_name}`,
  //         party_a_creator: userData?.id,
  //         party_a_creator_name: userData?.first_name + ' ' + userData?.last_name,
  //         party_b: expertData?.id,
  //         party_b_name: expertData?.first_name + ' ' + expertData?.last_name,
  //         message: messageInputRef.current.value,
  //         id: threadBySubjectId?.id,
  //       },
  //     });
  //     messageInputRef.current.value = '';
  //     setSelectedImageFile('');
  //     setShowZoomMeeting(false);
  //   } else if (selectedFileType !== 'message' && !messageInputRef?.current?.value) {
  //     dispatch({
  //       type: 'POST_MESSAGE',
  //       formData: {
  //         id: threadBySubjectId?.id,
  //         message: dynamicMessagePayloadWithAttachment(selectedFileType, imageUrlFromDB, docURL),
  //       },
  //     });
  //     setSelectedFileType('message');
  //     setSelectedImageFile('');
  //   } else if (
  //     threadBySubjectId?.messages?.length &&
  //     selectedFileType === 'message' &&
  //     messageInputRef?.current?.value !== ''
  //   ) {
  //     dispatch({
  //       type: 'POST_MESSAGE',
  //       formData: {
  //         id: threadBySubjectId?.id,
  //         message: messageInputRef.current.value,
  //         has_attachment: 0,
  //       },
  //     });
  //     messageInputRef.current.value = '';
  //     setSelectedImageFile('');
  //   }
  // };
  useEffect(() => {

    if (!threadBySubjectId?.id && !isUserThreadAlreadyExists) {
      createNewThread();
    }

  }, [threadBySubjectId])
  const onMessageSend = e => {
    if (isUserThreadAlreadyExists || !threadBySubjectId?.error) {
      if (showZoomMeeting) {
        dispatch({
          type: 'POST_ZOOM_MEETING',
          formData1: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            topic: expertData?.category?.name,
            agenda: `${getFullName()} and ${expertData?.expert?.first_name} ${expertData?.expert?.last_name}`,
            party_a_creator: userData?.id,
            party_a_creator_name: userData?.first_name + ' ' + userData?.last_name,
            party_b: expertData?.expert_id,
            party_b_name: expertData.expert.first_name + ' ' + expertData.expert.last_name,
            message: messageInputRef.current.value,
            id: threadBySubjectId?.id,
          },
        });
        messageInputRef.current.value = '';
        setSelectedImageFile('');
        setShowZoomMeeting(false);
      } else if (selectedFileType !== 'message' && !messageInputRef?.current?.value) {
        const requiredData = dynamicMessagePayloadWithAttachment(
          selectedFileType,
          imageUrlFromDB,
          docURL,
        );
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: requiredData?.message,
            has_attachment: requiredData?.has_attachment,
            attachment_type: requiredData?.attachment_type,
            type: requiredData?.attachment_type,
          },
        });
        setSelectedFileType('message');
        setSelectedImageFile('');
      } else if (

        selectedFileType === 'message' &&
        messageInputRef?.current?.value !== '' &&
        messageInputRef?.current?.value !== ' '
      ) {
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: messageInputRef.current.value,
            has_attachment: 0,
          },
        });
        messageInputRef.current.value = '';
        setSelectedImageFile('');
      }
      setMessageLoad(true);
    }

  };
  function format(val) {
    return moment(val).format('YYYYMMDD') !== moment().format('YYYYMMDD')
      ? moment(val).format('DD MMMM')
      : 'Today';
  }

  useEffect(() => {
    if (zoomMeetingSignature && zommMeetingSignatureLoading) {
      startMeeting(zoomMeetingSignature);
      dispatch({ type: 'ZOOM_MEETING_SIGNATURE_EMPTY' });
    }
  }, [zoomMeetingSignature]);

  async function startMeeting({ meetingNumber, passWord, role, signature }) {
    try {
      await client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        patchJsMedia: true,
        customize: {
          video: {
            isResizable: true,
            viewSizes: {
              default: {
                width: 1024,
                height: 650,
              },
              ribbon: {
                width: 316,
                height: 720,
              },
            },
          },
        },
      });
      await client.join({
        sdkKey: ZOOM_MEETING_SDK_KEY,
        signature: signature,
        meetingNumber: meetingNumber,
        password: passWord,
        userName: userData?.first_name + ' ' + userData?.last_name,
      });
    } catch (err) {
      message.error('Something went wrong while joining the meeting !');
    }
  }
  const handleMeetingFrame = (data) => {
    const createCallFrame = async () => {
      callFrameRef.current = DailyIframe.createFrame({
        iframeStyle: {
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: '0',
          backgroundColor: 'black',
          zIndex: 1000,
        },
        showLeaveButton: true,
      });

      callFrameRef.current.join({
        url: data?.data?.meeting_join_url,
        userName: userData?.first_name + ' ' + userData?.last_name,
      });
      setCallFrame(callFrameRef.current);
      callFrameRef.current.on('left-meeting', () => {
        callFrameRef.current.destroy();
        setCallFrame(null);
        setMeetingBackButtonVisible(false);

      });

      callFrameRef.current.on('joined-meeting', () => {
        setMeetingBackButtonVisible(true);
      });
    };

    if (isCallActive) {
      createCallFrame();
    }
  }
  async function handleAttachmentClick(id) {
    try {
      const resp = await postRequest('get-file-url', {
        messageId: id,
      });
      if (resp?.status === 200) {
        return resp.data.url;
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [imgsrc, setImgsrc] = useState('');
  // hanlde message on click method here
  const handleMessageClick = async (type, data, m) => {
    switch (type) {
      case 'JOIN_MEETING':
        handleMeetingFrame(data);
        break;
      case 'image':
        const url = await handleAttachmentClick(m?.id);
        setImgsrc(url);
        setIsModal(true);
        break;
      case 'docs':
        const a = document.createElement('a');
        a.href = await handleAttachmentClick(m?.id);
        a.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        break;
      case 'pdf':
        const a1 = document.createElement('a');
        a1.href = await handleAttachmentClick(m?.id);
        a1.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a1);
        a1.click();
        document.body.removeChild(a1);

        break;
      case 'excel':
        const a2 = document.createElement('a');
        a2.href = await handleAttachmentClick(m?.id);
        a2.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a2);
        a2.click();
        document.body.removeChild(a2);

        break;
      default:
        break;
    }
  };

  // pusher implemenation here
  useEffect(() => {
    if (selectedTabGroup) {
      const handlePusherMessageEvent = data => {
        dispatch({ type: 'ADD_NEW_MESSAGE_IN_THREAD', data: data.message });
      };
      channel.bind(selectedTabGroup, handlePusherMessageEvent);
      return () => {
        channel.unbind(selectedTabGroup, handlePusherMessageEvent);
      };
    }
  }, [selectedTabGroup]);

  // scroll to top for prev message
  useEffect(() => {
    if (message_area_ref?.current) {
      function scrollToTop() {
        const scrollPosition = message_area_ref.current.scrollTop;
        if (scrollPosition === 0) {
          let lastMessageContainer = message_area_ref.current?.querySelectorAll(
            '.message-container, .message-container-Right',
          );
          if (lastMessageContainer && lastMessageContainer.length) {
            let firstMessageDisplayId = lastMessageContainer[0].getAttribute('data-indexkey');
            setIsLoadPrevMessage(true);
            dispatch({
              type: 'GET_THREAD_BY_SUBJECT_ID',
              id: props.subjectId,
              firstMessageDisplayId,
            });
          }
        } else {
          setIsLoadPrevMessage(false);
        }
      }
      message_area_ref.current.addEventListener('scroll', scrollToTop);
      return () => {
        message_area_ref.current?.removeEventListener('scroll', scrollToTop);
      };
    }
  }, [threadBySubjectId?.messages, isLoading]);

  useEffect(() => {
    if (isAddNewMessageInThread) {
      setIsLoadPrevMessage(false);
      dispatch({ type: 'ADD_NEW_MESSAGE_IN_THREAD_ERROR' });
    }
    if (isLoadPrevMessage && addedMessagesLastIndexId) {
      const messageContainerRight = document.querySelector(
        '.message-container-Right[data-indexkey="' + addedMessagesLastIndexId + '"]',
      );
      const messageContainer = document.querySelector(
        '.message-container[data-indexkey="' + addedMessagesLastIndexId + '"]',
      );
      if (messageContainer) {
        messageContainer.scrollIntoView({ block: 'start' });
      } else if (messageContainerRight) {
        messageContainerRight.scrollIntoView({ block: 'start' });
      }
    }
  }, [isAddNewMessageInThread]); //use effct for while add new messaeg or fetch prev meessage fired  //

  return (
    <div
      style={{ backgroundColor: 'white', border: 'none' }}
      className="message-body"
      id="message-body"
    >
      {isModal && <ImagePreviewModal isModal={isModal} setIsModal={setIsModal} imgsrc={imgsrc} />}
      {callFrame && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            zIndex: 10000,
            height: '4.3vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: "5px 30px"
          }}
        >
          <div >
            <Button
              style={{
                color: '#1c64f2',
                backgroundColor: 'white',
                paddingLeft: '30px',
                paddingRight: '30px',
                border: 'none',

              }}
              htmlType="button"
              className=""
              size="small"
              onClick={() => callFrameRef?.current?.destroy()}
              hidden={meetingBackButtonVisible}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </div>

          <img src={MainLogo} alt="" height={"20rem"} />
          <span ></span>
        </div>
      )}

      {subjectId || isLoading ? (
        <div style={{ backgroundColor: 'white', position: 'relative' }} className="request-message-wrapper">
          <div className="request-message-details" style={{ position: 'relative' }}>
            {showZoomMeeting && <ZoomMeeting setShowZoomMeeting={setShowZoomMeeting} />}
            {isLoadPrevMessage && <Spin />}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: (height * 58) / 100,
                
                
                
              }}
              className="message-area"
              ref={message_area_ref}
            >
              {isLoading ? (
                <div
                  style={{ height: (height * 55) / 100, backgroundColor: 'white' }}
                  className="d-flex-a-c"
                >
                  <Spin />
                </div>
              ) : (
                threadBySubjectId?.messages
                  ?.sort((a, b) => a.id - b.id)
                  .map((m, i) => {
                    const prevMessage = selectedThreadData[0]?.messages[i - 1];
                    const prevMessageTimeStamp = format(prevMessage?.created_at);
                    const currentMessageTimeStamp = format(m?.created_at);
                    if (getUserData().id === m?.user_id) {
                      if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                        return (
                          <>
                            {format(m?.created_at) && (
                              <div className="date">
                                <div className="message-container-date-box">
                                  <p className="message-container-date-box-p">
                                    {format(m?.created_at)}
                                  </p>
                                </div>
                              </div>
                            )}
                            <RightMessage
                              m={m}
                              selectedThreadData={selectedThreadData}
                              handleMessageClick={handleMessageClick}
                            />
                          </>
                        );
                      } else {
                        return (
                          <RightMessage
                            m={m}
                            selectedThreadData={selectedThreadData}
                            handleMessageClick={handleMessageClick}
                          />
                        );
                      }
                    }
                    if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                      return (
                        <>
                          {format(m?.created_at) && (
                            <div className="date">
                              <div className="message-container-date-box">
                                <p className="message-container-date-box-p">
                                  {format(m?.created_at)}
                                </p>
                              </div>
                            </div>
                          )}
                          <LeftMessage
                            m={m}
                            type="group"
                            selectedThreadData={selectedThreadData}
                            handleMessageClick={handleMessageClick}
                          />
                        </>
                      );
                    } else {
                      return (
                        <LeftMessage
                          m={m}
                          type="group"
                          selectedThreadData={selectedThreadData}
                          handleMessageClick={handleMessageClick}
                        />
                      );
                    }
                  })
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="text-box">
              <div className="icon-write">
                {threadBySubjectId?.messages?.length && (
                  <div className="icon">
                    <Upload
                      name="attachment"
                      accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                      onChange={handleImageUpload}
                      showUploadList={false}
                      maxCount={1}
                      customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                    >
                      <img src={attachment} alt="" />
                    </Upload>
                  </div>
                )}
                <div style={{ width: '100%' }}>
                  {selectedImageFile ? (
                    <div
                      style={{
                        backgroundColor: '#eef1f4',
                        gap: '15px',
                        width: 'max-content',
                        padding: '10px 5px',
                        borderRadius: '8px',
                        alignItems: 'center',
                      }}
                      className="d-flex"
                    >
                      <div>
                        <img
                          height={25}
                          width={25}
                          src={placeholderBasedOnFileType(selectedFileType)}
                          alt=""
                        />
                      </div>
                      <div>{selectedImageFile}</div>
                      <Button
                        style={{ border: 'none', backgroundColor: 'transparent' }}
                        icon={<CloseCircleOutlined />}
                        size="small"
                        onClick={() => {
                          setSelectedImageFile(null);
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      autoFocus={true}
                      ref={messageInputRef}
                      required
                      className="write"
                      type="text"
                      placeholder="Write a message..."
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          onMessageSend(e);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="videosend"
                onClick={() => {
                  setShowZoomMeeting(true);
                }}
              >
                <VideoCameraOutlined />
              </div>
              <AddMeetingTime threadBySubjectId={threadBySubjectId} expertRecords={expertData} />
              <div
                onClick={e => onMessageSend(e)}
                loading={isImageUploadingToDBLoading}
                className="videosend"
              >
                <SendOutlined />
              </div>
            </div>
          </div>
          {newMessageLoad && (
            <Spin
              style={{
                position: 'absolute',
                top: '80%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </div>
      ) : type === 'detail' ? (
        <div
          style={{
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            height: (height * 63) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          Please select one of the chatroom.
        </div>
      )}
    </div>
  );
};
