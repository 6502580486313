import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Button, Modal, Rate, Tooltip, message } from 'antd';
import Cookies from 'js-cookie';
import QuotationModal from './modal/quatationModal';
import PaymentModal from './modal/paymentModal';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { AssignQuestionToExpertModal } from './modal/assignToModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RequestRejectModal } from './modal/requestRejectModal';
import { breakName, createMarkup, getRanksOfExperts, getUserData, role } from '../../helpers/functions';
import WhatsNextModal from './modal/WhatsNextModal';
import { nameRecommendationConstantForToolTip, ROLE_NAME } from '../../helpers/constant';
import Circleprogress from './circleprogress';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const AssignedToExpertCard = ({ data, setSelectedTab }) => {

  const dispatch = useDispatch();
  const [quotationModal, setQuotationModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [requestRejectModal, setRequestRejectModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(null);
  const [showFullProposal, setShowFullProposal] = useState(false);
  const [showWhatsNextModal, setShowWhatsNextModal] = useState(false);
  const [dontShowWhatsNext, setDontShowWhatsNext] = useState(false);
  const [ranks, setRanks] = useState([0, 0, 0, 0, 0])
  const navigate = useNavigate();
  const proposalLimit = 500;
  const { isDontShowWhatsNext } = useSelector(state => state.common);
  const [infoModel, setInfoModel] = useState(false);
  const state = useSelector(state => state);
  useEffect(() => {
    if (role() != ROLE_NAME.ADMIN) {
      dispatch({ type: 'RECOMMENDED_EXPERT_LIST_REQUEST', questionId: data?.status?.question_id });
    }
  }, [data?.status?.question_id])
  useEffect(() => {
    if (state?.expert?.recommendedExpertsList?.length > 0 && showAssignModal == null) {
      setRanks(getRanksOfExperts(state?.expert?.recommendedExpertsList, data?.expert?.id))
    } else {
      setRanks(ranks)
    }
  }, [state?.expert?.recommendedExpertsList.length, data?.status?.question_id])
  useEffect(() => {
    if (!Cookies.get(`dontShowWhatsNext_${data?.user?.id}`)) {
      dispatch({ type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL', userId: data?.user?.id });
    }
    if (isDontShowWhatsNext) {
      Cookies.set(`dontShowWhatsNext_${data?.user?.id}`, 'true', { expires: 365 });
    }
    const dontShowWhatsNext = Cookies.get(`dontShowWhatsNext_${data?.user?.id}`);
    if (!dontShowWhatsNext) {
      setDontShowWhatsNext(true);
    }
  }, [showAssignModal]);

  const handleExpertRequestAccept = () => {
    dispatch({
      type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: data?.id, is_approve: 1 },
    });
  };

  const handleCloseModal = () => {
    if (dontShowWhatsNext) {
      setShowWhatsNextModal(true);
    }
  };

  const parseProposal = proposal => {
    if (data?.status?.question_status?.status_order >= 7) {
      return proposal;
    } else {
      let fullName = data?.expert?.first_name + ' ' + data?.expert?.last_name;
      let updatedProposal = proposal.replace(
        new RegExp(fullName, 'g'),
        breakName(data?.expert?.first_name, data?.expert?.last_name),
      );
      return updatedProposal;
    }
  };

  return (
    <div className="request-assigned-to-container">
      {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
        <h3>Find another expert</h3>
      ) : (
        <h3>We recommend</h3>
      )}
      {
        (data?.status?.question_status?.status_order <= 9) &&
        <div className="assigned-to-profile-container">
          {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
            <div style={{ maxWidth: '70%' }}>
              Unfortunately, the expert you previously selected has rejected your request. Please
              select a different expert to assist you.
            </div>
          ) : (
            <>
              <div
                className="assigned-to-profile-left-container"
                onClick={() => navigate(`/find-expert-detail/${data?.expert?.id}`)}
              >
                <div className="assigned-to-profile-icon">
                  <img
                    alt="expert"
                    height={60}
                    width={60}
                    src={data?.expert_meta?.avatar ? data?.expert_meta?.avatar : expertIcon}
                  />
                </div>
                <div className="assigned-to-profile-detail">
                  <div className="assigned-to-profile-name">
                    {data?.status?.question_status?.status_order >= 7 ? (
                      data?.expert?.first_name + ' ' + data?.expert?.last_name
                    ) : (
                      <Tooltip
                        title={nameRecommendationConstantForToolTip?.title || ''}
                        color={nameRecommendationConstantForToolTip?.color}
                      >
                        {breakName(data?.expert?.first_name, data?.expert?.last_name)}
                      </Tooltip>
                    )}
                  </div>
                  <div className="assigned-to-profile-role">{data?.category?.name}</div>
                </div>
              </div>
            </>
          )}

          {data?.question_review?.length &&
            (data?.status?.question_status_id === 10 || data?.status?.question_status_id === 11) ? (
            <div className="assigned-to-rating-container">
              <div className="assigned-to-rating-container-div">My Review</div>
              <Rate disabled value={data?.question_review[0]?.rating} />
            </div>
          ) : null}

          <div className="assigned-to-profile-button-group">
            {setSelectedTab && (
              <Button
                className="assigned-to-profile-button"
                type="primary"
                onClick={() => setSelectedTab('messaging')}
              >
                Messaging
              </Button>
            )}
            {data?.status?.question_status_id !== 10 &&
              data?.status?.question_status_id !== 11 &&
              data?.status?.question_status_id < 4 &&
              data?.assign_by_admin === 0 ? (
              <Button
                className="assigned-to-profile-button"
                type="primary"
                onClick={() => setShowAssignModal(true)}
              >
                Find Another
              </Button>
            ) : null}
            {data?.status?.question_status_id !== 10 &&
              data?.status?.question_status_id !== 11 &&
              // data?.status?.question_status_id < 4 &&
              data?.assign_by_admin === 1 &&
              data?.expert_approved !== 1 ? (
              <>
                <Button
                  className="assigned-to-profile-button"
                  type={data?.expert_approved === 1 ? 'default' : 'primary'}
                  disabled={data?.expert_approved === 1}
                  onClick={() => {
                    if (data?.expert_approved === 0) {
                      handleExpertRequestAccept();
                    } else {
                      message.error('Waiting for expert approval.');
                    }
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="assigned-to-profile-button"
                  danger
                  disabled={data?.expert_approved === 1}
                  onClick={() => {
                    if (data?.expert_approved === 0) {
                      setShowAssignModal(true);
                    } else {
                      message.error('Waiting for expert approval.');
                    }
                  }}
                >
                  Other Options
                </Button>
              </>
            ) : null}
            {data?.status?.question_status_id === 5 && (
              <Button
                className="assigned-to-profile-button"
                type="primary"
                onClick={() => (setQuotationModal(true), setInfoModel(true))}
              >
                Quotation Details
              </Button>
            )}
          </div>
        </div>
      }

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: '1rem',
          borderRadius: '8px',
          border: '2px solid rgba(77,155,211)',
          padding: '.5rem',
          justifyContent: 'space-evenly',
          minHeight: '10rem',
        }}
      >
        <Circleprogress percentage={ranks[0]} heading="Exp. in service" info={{ "perfect": "Extensive expertise directly in this service area.", "moderate": "Significant experience in a related field.", "unsure": "Some transferable skills applicable to the service." }} />

        <Circleprogress percentage={ranks[1]} heading="Exp. in subcategory" info={{ "perfect": "Deep expertise specific to the service subcategory.", "moderate": "Experience with similar subcategories.", "unsure": "Limited exposure to the service subcategory." }} />

        <Circleprogress percentage={ranks[2]} heading="Exp. in industry" info={{ "perfect": "Extensive experience specific to the industry.", "moderate": "Proven track record in related industries.", "unsure": "Some familiarity with the industry." }} />

        <Circleprogress percentage={ranks[3]} heading="Positive Reviews" info={{ "perfect": "Consistently outstanding reviews.", "moderate": "Mostly positive reviews with minor issues.", "unsure": "Mixed reviews or limited feedback available." }} />

        <Circleprogress percentage={ranks[4]} heading="Location (Province)" info={{ "perfect": "Located in the same province.", "moderate": "Located in a neighboring province.", "unsure": "Located in a different region of Canada." }} />
        <Circleprogress percentage={ranks[7]} heading="Language" info={{ "perfect": "Located in the same province.", "moderate": "Located in a neighboring province.", "unsure": "Located in a different region of Canada." }} />
        <Circleprogress percentage={ranks[8]} heading="Availability" info={{ "perfect": "Located in the same province.", "moderate": "Located in a neighboring province.", "unsure": "Located in a different region of Canada." }} />
      </div>
      {
        data?.proposal ? (
          <div style={{ 'max-height': '100%' }}>
            <pre className="assigned-to-profile-proposal mt-4">
              {data.proposal.length > proposalLimit && !showFullProposal ? (
                <span
                  className="request-span"
                  dangerouslySetInnerHTML={createMarkup(
                    parseProposal(data.proposal).slice(0, proposalLimit) + '...',
                  )}
                />
              ) : (
                <span
                  className="request-span"
                  dangerouslySetInnerHTML={createMarkup(parseProposal(data.proposal))}
                />
              )}
            </pre>
            {data.proposal.length > proposalLimit ? (
              <Button onClick={() => setShowFullProposal(val => !val)}>
                {showFullProposal ? 'Show less' : 'Show more'}
              </Button>
            ) : null}
          </div>
        ) : null
      }

      {
        quotationModal && (
          <QuotationModal
            type="customer"
            open={quotationModal}
            close={() => setQuotationModal(false)}
            qid={data.id}
            questionData={data}
            openPayment={() => {
              setQuotationModal(false);
              setPaymentModal(true);
            }}
          />
        )
      }
      {
        paymentModal && (
          <PaymentModal
            type="customer"
            open={paymentModal}
            close={() => setPaymentModal(false)}
            quotationId={data.quotation_detail !== null ? data?.quotation_detail?.id : ''}
            quotationDetail={data}
          />
        )
      }

      <AssignQuestionToExpertModal
        visible={showAssignModal}
        setVisible={setShowAssignModal}
        rowData={data}
        variant="client"
        onClose={handleCloseModal}
        onClickNeedHelp={setRequestRejectModal}
      />

      <WhatsNextModal
        visible={showWhatsNextModal}
        setVisible={setShowWhatsNextModal}
        userId={data?.user?.id}
        expertName={`${data?.expert?.first_name} ${data?.expert?.last_name}`}
      />
      <RequestRejectModal
        visible={requestRejectModal}
        setShowModal={setRequestRejectModal}
        rawData={data}
        variant="client"
      />
      <Modal
        title={null}
        open={infoModel}
        footer={null}
        zIndex={99999999}
        onCancel={() => setInfoModel(false)}
        centered
      >
        <p style={{ fontSize: '18px', textAlign: 'center' }}>
          <div>
            <ExclamationCircleOutlined className='my-3' style={{ fontSize: '4rem', color: 'gray' }} />
          </div>
          All payments are securely processed through Stripe. We do not store any payment information on our servers.</p>
      </Modal>
    </div >
  );
};
