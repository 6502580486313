import { SALT_KEY, CURRENT_ROLE_NAME, pusher } from './constant';
import moment from 'moment';
import ImgPlaceholder from '../assets/images/image-placeholder.png';
import PdfPlaceholder from '../assets/images/pdf-placeholder.png';
import DocsPlaceholder from '../assets/images/docx-placeholder.png';
import ExcelPlaceholder from '../assets/images/excel-placeholder.png';
import { Button, Image } from 'antd';
import { CloseCircleOutlined, DownloadOutlined, FileImageFilled, VideoCameraOutlined } from '@ant-design/icons';
import pdfPlaceholder from '../assets/images/pdf-placeholder.png';
import docxPlaceholder from '../assets/images/docx-placeholder.png';
import excelPlaceholder from '../assets/images/excel-placeholder.png';
import ReactLinkify from 'react-linkify';
import { marked } from 'marked';
import momenttimezone from 'moment-timezone';
import axios from 'axios';
import { OTHER_SUB_CATEGORY_IDS } from './constant';

import PickMeetingDateAndTime from '../components/common/modal/pickMeetingDateAndTime';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRequest, postRequest } from './apiCall';

export const logout = () => {
  localStorage.removeItem('loggedInUser');
  localStorage.removeItem('jwt');
  localStorage.removeItem('remember');
  localStorage.removeItem('redirectLink');
  localStorage.removeItem('expertAvatar');
  pusher.unsubscribe(`${getUserData()?.id}`)
};

export const isLogin = () => {
  return getUserData() !== '';
};

export const isMenuVisible = (key = '', type = 'all') => {
  if (CURRENT_ROLE_NAME === 'streamline_admin') {
    return 'block';
  } else {
    if (type === 'admin') {
      return 'none';
    } else {
      return checkPermission(key) ? 'block' : 'none';
    }
  }
};
export function dateFormat(dateString) {
  const inputDate = new Date(dateString);

  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const year = inputDate.getFullYear();

  return `${year}-${month}-${day}`;
}
export const getUserData = () => {
  if (checkAndReturnLoginUser(true)) {
    try {
      return JSON.parse(checkAndReturnLoginUser());
    } catch (error) {
      localStorage.setItem('loggedInUser', '');
      localStorage.setItem('jwt', '');
      localStorage.setItem('jwtTmp', '');

      return '';
    }
  }
  return '';
};

export const getUData = () => {
  return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwtTmp')
    ? JSON.parse(decrypt(localStorage.getItem('loggedInUser')))
    : '';
};

export const checkAndReturnLoginUser = (returnBolean = false) => {
  if (returnBolean) {
    return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt');
  } else {
    return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')
      ? decrypt(localStorage.getItem('loggedInUser'))
      : '';
  }
};

export const accessToken = () => {
  if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt')) {
    return localStorage.getItem('jwt');
  } else if (localStorage.getItem('jwtTmp') !== null && localStorage.getItem('jwtTmp')) {
    return localStorage.getItem('jwtTmp');
  } else {
    return '';
  }
};

export const role = () => {
  if (checkAndReturnLoginUser(true)) {
    let userData = JSON.parse(checkAndReturnLoginUser());
    if (userData.role.length) {
      return userData.role[0].name;
    }
    return '';
  }
  return '';
};

export const getFullName = () => {
  if (checkAndReturnLoginUser(true)) {
    let userData = JSON.parse(checkAndReturnLoginUser());
    return userData?.first_name + ' ' + userData?.last_name;
  }
  return '';
};

export const showAvatarName = () => {
  let name = getFullName();
  if (name !== '') {
    let fullName = name.split(' ');
    let initials = '';
    if (fullName.length > 0) {
      if (fullName[0] !== undefined && fullName[0] !== '') {
        initials = fullName[0].charAt(0);
      }
      if (fullName[1] !== undefined && fullName[1] !== '') {
        initials = initials + fullName[1].charAt(0);
      }
    }
    return initials !== '' ? initials.toUpperCase() : '';
  }
  return '';
};

export const showCustomAvatarName = name => {
  if (name !== '') {
    let fullName = name.split(' ');
    let initials = '';
    if (fullName.length > 0) {
      if (fullName[0] !== undefined && fullName[0] !== '') {
        initials = fullName[0].charAt(0);
      }
      if (fullName[1] !== undefined && fullName[1] !== '') {
        initials = initials + fullName[1].charAt(0);
      }
    }
    return initials !== '' ? initials.toUpperCase() : '';
  }
  return '';
};

export const isHomePage = type => {
  if (type === '/' || type === '/home') {
    return true;
  }
  return false;
};

export const toTitleCase = str => {
  if (str !== undefined && str !== null && str.trim() !== '') {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }
  return str;
};

export const isImage = url => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const checkPermission = (key, type) => {
  if (checkAndReturnLoginUser(true)) {
    let userData = JSON.parse(checkAndReturnLoginUser());
    let permissions = userData?.permissions;
    if (permissions.length > 0) {
      return permissions.includes(key);
    } else {
      return false;
    }
  }
  return false;
};

export const isAuthorized = (allowedList = '', type = '') => {
  if (CURRENT_ROLE_NAME === 'streamline_admin') {
    return true;
  } else {
    if (type === 'admin') {
      return false;
    } else {
      return checkPermission(allowedList);
    }
  }
};

export const encrypt1 = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text => text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const decrypt1 = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded =>
    encoded
      .match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
};

// To create a cipher
export const encrypt = encrypt1(SALT_KEY);
//To decipher, you need to create a decipher and use it:
export const decrypt = decrypt1(SALT_KEY);

export const formatDate = date => {
  if (date !== null && date !== '') {
    return moment(date);
  } else {
    return null;
  }
};

export const inputFormatDate = date => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  } else {
    return date;
  }
};

export const formatDateTo = (date, format = 'MMM DD, YYYY') => {
  if (date !== null && date !== '') {
    return moment(date).format(format);
  } else {
    return null;
  }
};

export const getWindowSizeInPercent = (width, height) => {
  if (!height) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      (document.body.clientWidth * width) / 100
    );
  } else {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      (document.body.clientHeight * height) / 100
    );
  }
};

export const formatAmount = (amount, decimal = 2) => {
  if (amount !== undefined && amount !== null && amount && amount !== '') {
    amount = parseFloat(amount);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimal,
    }).format(amount);
  } else if (amount === null) {
    return '$0.00';
  } else {
    return amount;
  }
};

export const placeholderBasedOnFileType = selectedFileType => {
  switch (selectedFileType) {
    case 'image': {
      return ImgPlaceholder;
    }
    case 'pdf': {
      return PdfPlaceholder;
    }
    case 'docs': {
      return DocsPlaceholder;
    }
    case 'excel': {
      return ExcelPlaceholder;
    }
    default:
      return '';
  }
};

export const dynamicMessagingCard = (type, body, variant, message, params) => {
  const customStyle = { borderRadius: variant === 'archive' ? '16px' : undefined };
  const userData = getUserData();

  switch (type) {
    case 'image': {
      return (

        <div className="message-box">
          <p style={customStyle} >
            <FileImageFilled />
          </p>
        </div>
      );
    }
    case 'pdf': {
      return (
        <div className="message-box">
          <p style={customStyle} className="d-flex-a-c">
            <div>
              <img style={{ width: '2vw', height: '2vw' }} src={pdfPlaceholder} alt="pdf" />
            </div>
            <p>
              {/* <a target="_blank" href={body} rel="noreferrer"> */}
              {body.substring(body.lastIndexOf('/') + 1)}
              {/* </a> */}
            </p>
            <Button type="default" shape="circle" icon={<DownloadOutlined />} size="large" />
          </p>
        </div>
      );
    }
    case 'docs': {
      return (
        <div className="message-box">
          <p style={customStyle} className="d-flex-a-c">
            <div>
              <img style={{ width: '2vw', height: '2vw' }} src={docxPlaceholder} alt="pdf" />
            </div>
            <p>{body.substring(body.lastIndexOf('/') + 1)}</p>
            {/* <a href={body} download> */}
            <Button type="default" shape="circle" icon={<DownloadOutlined />} size="large" />
            {/* </a> */}
          </p>
        </div>
      );
    }
    case 'excel': {
      return (
        <div className="message-box">
          <p style={customStyle} className="d-flex-a-c">
            <div>
              <img style={{ width: '2.5vw', height: '2.5vw' }} src={excelPlaceholder} alt="pdf" />
            </div>
            <p>{body.substring(body.lastIndexOf('/') + 1)}</p>
            {/* <a href={body} download> */}
            <Button type="default" shape="circle" icon={<DownloadOutlined />} size="large" />
            {/* </a> */}
          </p>
        </div>
      );
    }
    case 'zoom': {
      return (
        <div className="message-box">
          <p style={customStyle}>
            {body.msg}
            <div className="zoom-meeting-chatview">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(28,100,242)',
                  borderRadius: '8px',
                  padding: '10px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}
                >
                  <h1 style={{ color: 'white' }}> Meeting with
                    <div style={{ fontSize: '1rem', marginTop: '0.6rem', textAlign: 'right' }}>
                      {
                        userData?.id != body?.data?.party_a_creator ? body.data.party_a_creator_name : body.data.party_b_name
                      }
                    </div>
                  </h1>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
                >
                  <span style={{ color: 'white' }}>click here</span>
                  <VideoCameraOutlined
                    style={{ color: 'rgba(255,255,255,0.5)', fontSize: '5rem' }}
                  />
                </div>
              </div>
            </div>
          </p>
        </div>
      );
    }
    case 'calendar': {
      return (

        <div className="message-box">
          <p style={customStyle}>
            <div style={{ borderLeft: '2px solid rgba(24,24,24)', padding: '10px' }}>
              Duration :{body?.duration} minutes
              <br />
              <PickMeetingDateAndTime newdata={body} />
              <span>if you want to book a solo appointment</span>
            </div>
          </p>
        </div>
      );
    }
    case 'schedule': {
      const timezone = getUserData()?.locations?.[0]?.timezone || "";
      const { convertedDate, convertedTime, utcOffset } = convertTimeZone(body?.timezone, body?.meetingTiming?.split(" ")[0], body?.meetingTiming?.split(" ")[1],
        timezone);
      return (
        <div className="message-box">
          <p style={customStyle}>
            <div
              style={{
                borderLeft: '2px solid rgba(24,24,24)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              { }
              <span>Duration: {body?.duration} minutes</span>
              <span>
                Date:{' ' + convertedDate}
              </span>
              <span>Time: {convertedTime}</span>
              <span>Time Zone : {timezone + ", UTC/GMT " + utcOffset}</span>
            </div>
          </p>
        </div>
      );
    }
    default: {
      return (
        <div className="message-box">
          <p>
            <ReactLinkify>{body}</ReactLinkify>
          </p>
        </div>
      );
    }
  }
};

export const dynamicMessagePayloadWithAttachment = (type, imageUploadUrl, docsUploadUrl) => {
  switch (type) {
    case 'image': {
      return {
        message: imageUploadUrl,
        has_attachment: 1,
        attachment_type: type,
      };
    }
    case 'pdf': {
      return {
        message: docsUploadUrl,
        has_attachment: 1,
        attachment_type: type,
      };
    }
    case 'docs': {
      return {
        message: docsUploadUrl,
        has_attachment: 1,
        attachment_type: type,
      };
    }
    case 'excel': {
      return {
        message: docsUploadUrl,
        has_attachment: 1,
        attachment_type: type,
      };
    }
    default: {
      return;
    }
  }
};

export const returnQuestionStatusBasedOnId = id => {
  switch (id) {
    case 1:
      return 'Request Submitted';
    case 2:
      return 'Finding a match';
    case 3:
      return 'Assigned';
    case 4:
      return 'Quotation Received';
    case 5:
      return 'Quotation Accepted';
    case 6:
      return 'Escrow Funded';
    case 7:
      return 'Job started';
    case 8:
      return 'Solved';
    case 9:
      return 'Cancel';
    case 10:
      return 'Completed';
    default:
      return;
  }
};

export const getExpertRejectionObject = data => {
  if (data?.length) {
    return data?.map(el => {
      if (el.reject_by_expert === 1) {
        return el;
      }
    });
  }
};

export const getClientRejectionObject = data => {
  if (data?.length) {
    return data?.map(el => {
      if (el.reject_by_client === 1) {
        return el;
      }
    });
  }
};
export const isValidMimeType = file => {
  const validMimeTypes = [
    'image/jpeg',
    'image/bmp',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/pdf', // .pdf
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ];

  return file && validMimeTypes.includes(file.type);
};
export const checkImageSize = file => {
  return file && file.size <= 2097152;
};

export const isImageType = file =>
  file === 'image/jpeg' || file === 'image/jpg' || file === 'image/png' || file === 'image/gif';
export const isPdfType = file => file === 'application/pdf';
export const isExcelType = file =>
  file === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const isDocsType = file =>
  file === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const getSubCategoryName = data => {
  if (OTHER_SUB_CATEGORY_IDS.includes(data?.sub_category?.id)) {
    return data?.other_sub_category_name;
  } else {
    return data?.sub_category?.name ?? '-';
  }
};

// Convert markdown into HTML
export const createMarkup = markdownText => {
  return { __html: marked(markdownText) };
};

export const getRandomLightHexColor = (rangeStart, rangeEnd) => {
  // Function to generate a random integer between min and max (inclusive)
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Generate three random values in the range of 128 to 255 (inclusive)
  const r = getRandomInt(rangeStart, rangeEnd);
  const g = getRandomInt(rangeStart, rangeEnd);
  const b = getRandomInt(rangeStart, rangeEnd);
  // Convert the values to a hexadecimal string with two digits each
  const hexColor =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0');
  return hexColor.toUpperCase(); // Ensure uppercase for consistency
};

// ------------------------- edited by prince start ------------------------
// export const NextSevenDaysNames = () => {
//   const days = [];

//   const options = { weekday: 'long' };
//   for (let i = 0; i < 7; i++) {
//     const date = new Date();
//     date.setDate(date.getDate() + i);
//     const dayName = date.toLocaleDateString('en-US', options);
//     days.push(dayName);
//   }
//   return days;
// };

export const NextSevenDaysNames = () => {
  const nextSevenDaysName = [];
  const nextSevenDates = [];

  const options = { weekday: 'long' };
  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    const dayName = date.toLocaleDateString('en-US', options);
    const formattedDate = date.toISOString().split('T')[0];
    nextSevenDaysName.push(dayName);
    nextSevenDates.push(formattedDate);
  }
  return { nextSevenDaysName, nextSevenDates };
}


// --------------------------------- edited------------------------------

function convertTimeZone(originalTimeZone, date, time, targetTimeZone) {
  const originalDateTime = momenttimezone.tz(`${date} ${time}`, `YYYY-MM-DD HH:mm`, originalTimeZone);
  const convertedDateTime = originalDateTime.tz(targetTimeZone);

  const convertedDate = convertedDateTime.format('YYYY-MM-DD');
  const convertedTime = convertedDateTime.format('HH:mm:ss');
  const utcOffset = convertedDateTime.format('Z'); // Get the UTC offset

  return { convertedDate, convertedTime, utcOffset };
}

export function formatNotification(n) {
  if (typeof n?.data === 'string') {
    return [JSON.parse(n?.data)?.title, JSON.parse(n?.data)?.message];
  } else if (n?.data) {
    return [JSON.parse(n?.data[0])?.title, JSON.parse(n?.data[0])?.message];
  }
}
export function getDate(n) {

  if (n.created_at) {
    return new Date(n?.created_at).toLocaleString();
  }
  else {
    // console.log('JSON.parse(n?.data[0])?.created_at: ', JSON.parse(n?.data[0])?.notification?.created_at);
    return new Date(JSON.parse(n?.data[0])?.notification?.created_at).toLocaleString();
  }
}

export function handleNotification(type, data) {
  switch (type) {
    case 'accept-expert':
      return '/dashboard/expert/request?tab=newRequest';
    case 'job-created':
      return '/dashboard/expert/request?tab=question';
    case 'accept-question':
      return '/my-request/';
    case 'credits-added-to-account':
      return '/dashboard/expert/request/';
    case 'profile-update':
      return '/profile';
    case 'reject-from-expert':
      return '/my-request/';
    case 'escrow-funded':
      return '/my-request/';
    case 'job-completed':
      return '/my-request/'
    case 'received-quotation-from-expert':
      return '/my-request/';
    default:
      return '/notifications';
  }
}

export function breakName(firstName, lastName) {

  if (!firstName) return null;
  firstName = firstName.split(" ")[0];
  return firstName + ' ' + (lastName?.[0]?.toUpperCase() || "") + '.';
}
export function truncateString(str) {
  // Check if the string length is greater than 100
  if (str.length > 100) {
    // Return the first 100 characters followed by "..."
    return str.substring(0, 100) + '...';
  } else {
    // Return the original string if it's 100 characters or fewer
    return str;
  }
}

/**
 * If the proposal is in the accepted status, return the proposal as is.
 * Otherwise, replace the full name of the expert with a shortened version
 * that only shows the first name and the first letter of the last name.
 * @param {string} proposal - The proposal text.
 * @param {string} firstname - The first name of the expert.
 * @param {string} lastname - The last name of the expert.
 * @param {number} status - The status of the proposal.
 * @return {string} The updated proposal text.
 */
export const parseProposal = (proposal, firstname, lastname, status) => {
  if ((status >= 7)) {
    return proposal;
  } else {
    let fullName = firstname + ' ' + lastname
    let updatedProposal = proposal.replace(new RegExp(fullName, 'g'), breakName(firstname, lastname));
    return updatedProposal;
  }
}

export function convertDateToTimezone(time) {
  const userTimezone = getUserData()?.locations[0]?.timezone;
  return momenttimezone.utc(time).tz(userTimezone).format('MMM DD, YYYY')
}
export function convertTimeToTimezone(time) {
  const userTimezone = getUserData()?.locations[0]?.timezone;

  return momenttimezone.utc(time).tz(userTimezone).format('hh:mm A')
}
export function getRanksOfExperts(experts, expert_id) {
  let ranks;
  for (let i = 0; i < experts.length; i++) {
    if (experts[i].id === expert_id) {
      ranks = experts[i].total_rank_fields
    }
  }
  return ranks?.split(',') || [0, 0, 0, 0, 0, 0, 0, 0]
}


export function getFileNameFromUrl(url, numberOfChars) {
  const fileName = url.split('/').pop().replace('accountizer_', '');
  const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
  const extension = fileName.substring(fileName.lastIndexOf('.'));

  const newName = nameWithoutExtension.substring(0, numberOfChars) + "..." + nameWithoutExtension.substring(nameWithoutExtension.length - numberOfChars) + extension;
  return newName;
}

export function formatDateBlog(dateStr) {
  const date = new Date(dateStr);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
}
